import styled from '@emotion/styled';

import { orderedBreakpointKeys, screen } from '@/components/common/breakpoints';

import type { BorderColourTokenType } from '@/types/colours';

type Orientation = 'horizontal' | 'vertical' | 'none';

type OrientationProps = {
  base?: Orientation;
  sm?: Orientation;
  md?: Orientation;
  lg?: Orientation;
  xl?: Orientation;
};

export interface DividerProps {
  orientation?: OrientationProps | Orientation;
  role?: string;
  thickness?: string;
  colour?: BorderColourTokenType;
}

export const Divider = ({
  orientation,
  role = 'separator',
  colour = '--border-warm-subtle',
  thickness = '1px',
}: DividerProps) => {
  const orientationProps: OrientationProps =
    typeof orientation === 'string' ? { base: orientation } : orientation || {};

  return (
    <DividerElement
      role={role}
      orientation={orientationProps}
      thickness={thickness}
      colour={colour}
    />
  );
};

const getOrientationStyles = (orientation: Orientation, thickness: string) => {
  switch (orientation) {
    case 'horizontal':
      return `
        height: ${thickness};
        width: 100%;
        display: block;
      `;
    case 'vertical':
      return `
        width: ${thickness};
        height: 100%;
        display: block;
      `;
    case 'none':
      return `
        display: none;
      `;
  }
};

const getOrientationBreakpoints = (
  orientation: OrientationProps,
  thickness: string,
) => {
  const styles = [];

  orderedBreakpointKeys.forEach((breakpoint) => {
    const orientationValue = orientation[breakpoint as keyof OrientationProps];

    if (breakpoint === 'base' && orientationValue) {
      styles.push(getOrientationStyles(orientationValue, thickness));
    }

    if (orientationValue) {
      styles.push(`
        ${screen[breakpoint]} {
            ${getOrientationStyles(orientationValue, thickness)}
          }
        `);
    }
  });

  return styles.join(' ');
};

const DividerElement = styled.hr<
  Pick<DividerProps, 'orientation' | 'colour' | 'thickness'>
>`
  background-color: ${({ colour }) => `var(${colour})`};
  border: none;
  margin: 0;

  ${({ orientation, thickness }) =>
    typeof orientation === 'string'
      ? getOrientationStyles(orientation, thickness)
      : getOrientationBreakpoints(orientation, thickness)}
`;
