import { isDarkBackground } from '@/lib/colourUtils';

import type { SurfaceColourTokenType } from '@/types/colours';

const decorativeMap: {
  [key: string]: { color: string; positionDesktop: string; positionMobile };
} = {
  'advanced-data-fellowship-topup': {
    color: '--surface-inverse-base',
    positionDesktop: '0 calc(calc(100% + 115px) * -1)',
    positionMobile: '50% 150%',
  },
  'ai-for-business-value': {
    color: '--surface-inverse-base',
    positionDesktop: '0 0',
    positionMobile: '50% 50%',
  },
  'ai-powered-productivity': {
    color: '--surface-inverse-base',
    positionDesktop: '80% -15%',
    positionMobile: '50% 15%',
  },
  'data-fellowship': {
    color: '--surface-galaxy',
    positionDesktop: '45% 50%',
    positionMobile: '50% 30%',
  },
  'advanced-software-engineering': {
    color: '--surface-galaxy',
    positionDesktop: '50px 1%',
    positionMobile: 'calc(100% + 45px) 1%',
  },
  'software-engineering': {
    color: '--surface-galaxy',
    positionDesktop: '50px 1%',
    positionMobile: 'calc(100% + 45px) 1%',
  },
  'business-transformation': {
    color: '--surface-primary',
    positionDesktop: 'calc(100% + 25px) -75%',
    positionMobile: 'calc(100% + 25px) -25%',
  },
  'transformative-leadership': {
    color: '--surface-primary',
    positionDesktop: 'calc(100% + 110px) -60%',
    positionMobile: 'calc(100% + 135px) -25%',
  },
  'digital-business-accelerator': {
    color: '--surface-primary',
    positionDesktop: 'calc(100% + 25px) 35%',
    positionMobile: 'calc(100% + 25px) 13%',
  },
  'project-management': {
    color: '--surface-primary',
    positionDesktop: 'calc(100% + 180px) 36%',
    positionMobile: 'calc(100% + 250px) 13%',
  },
  'applied-data-engineering': {
    color: '--surface-dark-eclipse',
    positionDesktop: '50px 15%',
    positionMobile: 'calc(100% + 15px) 30%',
  },
  'data-insights-for-business-decisions': {
    color: '--surface-dark-eclipse',
    positionDesktop: '100% 10%',
    positionMobile: '100% 40%',
  },
  'advance-data-fellowship': {
    color: '--surface-eclipse',
    positionDesktop: '0 15%',
    positionMobile: 'calc(100% + 65px) 15%',
  },
  'technology-consulting': {
    color: '--surface-eclipse',
    positionDesktop: 'calc(100% + 50px) -50%',
    positionMobile: 'calc(100% + 20px) 10%',
  },
};

export const getTokenColor = (decorative: string): string => {
  return decorative && decorativeMap[decorative.toLowerCase()].color;
};

export const getBackgroundColor = (token: string): string => {
  return `var(${token})`;
};

export const getDecorativeUrl = (decorative: string): string => {
  return `/images/decorative-programme/${decorative.toLowerCase()}`;
};

export const getDecorativeImage = (
  decorative: string,
): { desktop: string; mobile: string; baseUrl: string } => {
  const baseUrl = `/images/decorative-programme/${decorative?.toLowerCase()}`;
  return {
    desktop: `url(${baseUrl}-desktop.svg)`,
    mobile: `url(${baseUrl}-mobile.svg)`,
    baseUrl: baseUrl,
  };
};

export const getDecorativePosition = (decorative: string) => {
  const position = decorativeMap[decorative.toLowerCase()];
  return {
    desktop: position.positionDesktop,
    mobile: position.positionMobile,
  };
};

export const getTextColor = (decorative: string) => {
  const backgroundColor = getTokenColor(decorative);
  const isDark = isDarkBackground(backgroundColor as SurfaceColourTokenType);

  const textColorMap = {
    heading: isDark ? 'var(--text-inverse-strong)' : 'var(--text-strong)',
    subheading: isDark
      ? 'var(--text-inverse-warm-base)'
      : 'var(--text-warm-base)',
    caption: isDark
      ? 'var(--text-inverse-warm-subtle)'
      : 'var(--text-warm-subtle)',
    link: isDark ? 'var(--text-inverse-strong)' : 'var(--text-action)',
  };

  return textColorMap;
};
