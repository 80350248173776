import { useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';

export const useHandleSlideChange = () => {
  const swiper = useSwiper();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleSlideChange = () => {
      setActiveIndex(swiper.activeIndex);
    };

    swiper.on('slideChange', handleSlideChange);
    return () => {
      swiper.off('slideChange', handleSlideChange);
    };
  }, [swiper]);

  return { activeIndex };
};

export const useAutoplayOverride = ({ index, slidesPerView }) => {
  const swiper = useSwiper();

  const [autoplayRunning, setAutoplayRunning] = useState(true);
  const [overrideAutoplay, setOverrideAutoplay] = useState(false);

  useEffect(() => {
    if (!overrideAutoplay) {
      setAutoplayRunning(swiper.autoplay?.running);
    }
  }, [overrideAutoplay, swiper.autoplay?.running]);

  const paginationHandler = () => {
    swiper.slideTo(index * slidesPerView);
    swiper?.autoplay.stop();
    setOverrideAutoplay(true);
  };

  return { autoplayRunning, overrideAutoplay, paginationHandler };
};
