const decorativeMap: {
  [key: string]: { right: string; top: string };
} = {
  'advanced-data-fellowship-topup': {
    right: '-150px',
    top: '-170px',
  },
  'ai-for-business-value': {
    right: '-30px',
    top: '-30px',
  },
  'ai-powered-productivity': {
    right: '-200px',
    top: '-10px',
  },
  'data-fellowship': {
    right: '-600px',
    top: '-800px',
  },
  'advanced-software-engineering': {
    right: '0px',
    top: '0px',
  },
  'software-engineering': {
    right: '0px',
    top: '0px',
  },
  'business-transformation': {
    right: '0px',
    top: '10px',
  },
  'transformative-leadership': {
    right: '-150px',
    top: '15px',
  },
  'digital-business-accelerator': {
    right: '-80px',
    top: '-5px',
  },
  'project-management': {
    right: ' -300px',
    top: '-50px',
  },
  'applied-data-engineering': {
    right: '-120px',
    top: '-100px',
  },
  'data-insights-for-business-decisions': {
    right: '-40px',
    top: '-75px',
  },
  'advance-data-fellowship': {
    right: '-30px',
    top: '-30px',
  },
  'technology-consulting': {
    right: '0px',
    top: '0px',
  },
};

export const getProgrammeCardDecorativePosition = (decorative: string) => {
  return decorativeMap[decorative?.toLowerCase()];
};
