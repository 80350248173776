import styled from '@emotion/styled';

import { IconOnlyButton } from '@/components/common/Buttons';

export const ColourfulArrows = ({ prevButtonRef, nextButtonRef }) => {
  return (
    <ControlsContainer>
      <IconContainer ref={prevButtonRef} className="swiper-button-prev">
        <IconOnlyButton
          variant="solid-light"
          icon="ArrowLeft"
          aria-label="previous slides"
          size="md"
        />
      </IconContainer>
      <IconContainer ref={nextButtonRef} className="swiper-button-next">
        <IconOnlyButton
          variant="solid-light"
          aria-label="next slides"
          size="md"
        />
      </IconContainer>
    </ControlsContainer>
  );
};

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: var(--space-component-sm);
`;

const IconContainer = styled.button`
  background: unset;
  border: unset;
  cursor: pointer;

  &.swiper-button-disabled {
    opacity: 50%;
  }
`;
