import styled from '@emotion/styled';

import { Icon } from '@/components/common/Icon';

export const DefaultArrows = ({ prevButtonRef, nextButtonRef }) => {
  return (
    <ControlsContainer>
      <IconContainer ref={prevButtonRef} className="swiper-button-prev">
        <Icon icon="ChevronLeft" size="20px" colour="--icon-strong" />
      </IconContainer>
      <IconContainer ref={nextButtonRef} className="swiper-button-next">
        <Icon icon="ChevronRight" size="20px" colour="--icon-strong" />
      </IconContainer>
    </ControlsContainer>
  );
};

const ControlsContainer = styled.div`
  display: flex;
  gap: var(--space-component-lg);
`;

const IconContainer = styled.button`
  background: unset;
  border: unset;
  cursor: pointer;
  padding: var(--spacing-125);
  &.swiper-button-disabled {
    svg {
      color: var(--icon-warm-subtle);
    }
  }
  :not(&.swiper-button-disabled) {
    &:hover,
    &:focus {
      border-radius: var(--radius-component-full);
      background: var(--surface-warm-strong);
    }
  }
`;
