import styled from '@emotion/styled';

export const ProgressBar = styled.div<{ isActive: boolean }>`
  position: absolute;

  height: ${({ isActive }) => (isActive ? `2px` : '1px')};
  width: 100%;
  border-radius: var(--radius-full);
  background: var(--border-warm-base);
  border: none;
  padding: 0;
`;

export const ProgressBarInner = styled.div`
  --progress: 0;
  position: absolute;
  height: 3px;
  position: relative;
  top: -0.5px;
  width: calc(var(--progress) * 100%);
  max-width: 100%;
  background: var(--icon-action);
  transition: width 0.05s linear;
  border-radius: var(--radius-full);
  overflow: hidden;
`;
