import styled from '@emotion/styled';

import '@/components/common/Badge';

import { screen } from '@/components/common/breakpoints';
import { HeadingLevel } from '@/components/common/MarkUp';
import { Surface } from '@/components/common/Surface';
import DiagonalDecoration from '@/public/images/decorative/diagonal-lines-decoration.svg';
import HorizontalDecoration from '@/public/images/decorative/horizontal-lines-decoration.svg';
import VerticalDecoration from '@/public/images/decorative/vertical-lines-decoration.svg';

import type { IconColourTokenType, SurfaceColourType } from '@/types/colours';

interface DecorativeCardProps {
  backgroundColour?: SurfaceColourType;
  decoration?: {
    enable?: boolean;
    variant?: 'vertical' | 'horizontal' | 'diagonal';
    decorationColourToken?: string;
  };
  children: React.ReactNode;
}

export const DecorativeCard = ({
  backgroundColour,
  decoration,
  children,
}: DecorativeCardProps) => {
  const backgroundColourToken = backgroundColour?.token || '--surface-primary';

  const renderDecoration = (variant) => {
    switch (variant) {
      case 'vertical':
        return (
          <StyledVerticalDecoration
            colour={decoration?.decorationColourToken}
          />
        );
      case 'diagonal':
        return (
          <StyledDiagonalDecoration
            colour={decoration?.decorationColourToken}
          />
        );
      default:
        return (
          <StyledHorizontalDecoration
            colour={decoration?.decorationColourToken}
          />
        );
    }
  };

  return (
    <HeadingLevel>
      <StyledSurface
        surfaceColour={backgroundColourToken}
        borderRadius="--radius-component-xl"
      >
        {decoration?.enable && renderDecoration(decoration?.variant)}
        {children}
      </StyledSurface>
    </HeadingLevel>
  );
};

const StyledSurface = styled(Surface)`
  overflow: hidden;
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  ${screen.md} {
    width: calc(100% - (100% / 6));
  }

  ${screen.lg} {
    width: 66%;
  }
`;

const StyledVerticalDecoration = styled(VerticalDecoration)<{
  colour: IconColourTokenType;
}>`
  position: absolute;
  top: -195px;
  right: -500px;
  z-index: 1;

  path {
    stroke: ${({ colour }) => `var(${colour})`};
  }
`;
const StyledHorizontalDecoration = styled(HorizontalDecoration)<{
  colour: IconColourTokenType;
}>`
  position: absolute;
  right: unset;
  top: -203px;
  left: 150px;
  z-index: 1;

  path {
    stroke: ${({ colour }) => `var(${colour})`};
  }

  ${screen.sm} {
    right: -400px;
    left: unset;
  }
`;
const StyledDiagonalDecoration = styled(DiagonalDecoration)<{
  colour: IconColourTokenType;
}>`
  position: absolute;

  top: -125px;
  right: unset;
  left: 150px;

  z-index: 1;

  path {
    stroke: ${({ colour }) => `var(${colour})`};
  }

  ${screen.sm} {
    top: -122px;
    left: unset;
    right: -400px;
  }
`;
