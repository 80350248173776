/* eslint-disable jsx-a11y/alt-text */
import styled from '@emotion/styled';
import { PortableText } from '@portabletext/react';

import { breakpoints, screen } from '@/components/common/breakpoints';
import { BulletPoints } from '@/components/common/bulletpoints';
import { Button } from '@/components/common/Buttons';
import { HeadingLevelProvider } from '@/components/common/Heading/context';
import { Image } from '@/components/common/image';
import { Surface } from '@/components/common/Surface';
import { Title } from '@/components/common/Title';
import { Stack } from '@/components/layout/Stack';
import { blockUrlWithAudience } from '@/lib/utils';

import type { TitleProps } from '@/components/common/Title/types';
import type { SurfaceColourType } from '@/types/colours';
import type {
  BlockWithAudience,
  CommonCTAProps,
  HotspotImage,
} from '@/types/shared';
import type { SanityBlock, SanityKeyed } from 'sanity-codegen';

export interface SliderCarouselCardProps extends BlockWithAudience {
  _key?: string;
  _type?: 'sliderCarouselCard';
  image?: HotspotImage & {
    alternateText?: string;
    isDecorativeImage?: boolean;
  };
  // backgroundColour should keep it's name aligned with IconThemeOptions
  backgroundColour?: SurfaceColourType;
  title: TitleProps;
  bulletPoints?: Array<SanityKeyed<SanityBlock>>;
  primaryCTA?: CommonCTAProps;
  secondaryCTA?: CommonCTAProps;
  maxAmountCTA?: 2 | 1 | 0;
}

export const SliderCarouselCard = ({
  maxAmountCTA,
  backgroundColour,
  title,
  image,
  primaryCTA,
  secondaryCTA,
  bulletPoints,
  pageAudience,
  isAudienceSwitcherEnabled,
}: SliderCarouselCardProps) => {
  const backgroundColourToken =
    backgroundColour?.token ?? '--surface-warm-base';

  const addedCTAPadding =
    maxAmountCTA - (primaryCTA?.text ? 1 : 0) - (secondaryCTA?.text ? 1 : 0);

  return (
    <Surface
      padding={false}
      surfaceColour={backgroundColourToken}
      borderRadius="--radius-component-xl"
      className="surface"
      fill={true}
    >
      <HeadingLevelProvider level={3}>
        {!!image?.asset && (
          <ImageContainer>
            <Image {...image} isDecorativeImage />
          </ImageContainer>
        )}
        <CardContainer
          maxAmountCTA={maxAmountCTA}
          addedPadding={addedCTAPadding}
        >
          <StyledStack
            spacing="--space-component-lg"
            hasPrimaryCTA={!!primaryCTA?.text}
            maxAmountCTA={maxAmountCTA}
          >
            <Title {...title} variant="block" size="md" />

            {!!bulletPoints && (
              <StyledBulletPoints
                pointColour="--icon-warm-base"
                bulletPointStyle="check"
                paddingBetween="--space-component-xl"
              >
                <PortableText value={bulletPoints} />
              </StyledBulletPoints>
            )}
          </StyledStack>
          {!!primaryCTA?.text && (
            <CTAContainer>
              {!!secondaryCTA?.text && (
                <ButtonStyled
                  size="lg"
                  variant="flat-light"
                  href={blockUrlWithAudience(
                    secondaryCTA.url,
                    secondaryCTA.audienceRef,
                    pageAudience,
                    secondaryCTA.noAudienceToggle,
                    isAudienceSwitcherEnabled,
                  )}
                  aria-label={secondaryCTA?.accessibleLabel}
                  className={
                    secondaryCTA.trackingTag
                      ? `tracking-${secondaryCTA.trackingTag.blockName}__${secondaryCTA.trackingTag.blockAction}`
                      : ''
                  }
                >
                  {secondaryCTA.text}
                </ButtonStyled>
              )}
              <ButtonStyled
                size="lg"
                icon="ArrowRight"
                hideIconOnMobile
                variant="solid-light"
                href={blockUrlWithAudience(
                  primaryCTA.url,
                  primaryCTA.audienceRef,
                  pageAudience,
                  primaryCTA.noAudienceToggle,
                  isAudienceSwitcherEnabled,
                )}
                aria-label={primaryCTA?.accessibleLabel}
                iconPosition="right"
                className={
                  primaryCTA.trackingTag
                    ? `tracking-${primaryCTA.trackingTag.blockName}__${primaryCTA.trackingTag.blockAction}`
                    : ''
                }
              >
                {primaryCTA.text}
              </ButtonStyled>
            </CTAContainer>
          )}
        </CardContainer>
      </HeadingLevelProvider>
    </Surface>
  );
};

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  img {
    object-fit: cover;
    border-radius: var(--radius-component-xl) var(--radius-component-xl) 0px 0px;
    height: 200px;
  }
`;

const StyledStack = styled(Stack)<{
  hasPrimaryCTA: boolean;
  maxAmountCTA: 2 | 1 | 0;
}>`
  ${({ maxAmountCTA, hasPrimaryCTA }) =>
    maxAmountCTA > 0 &&
    `padding-bottom:
    ${
      hasPrimaryCTA
        ? 'var(--space-component-xxl)'
        : 'calc(var(--space-component-xxl) + 3rem)'
    }`};
`;

const ButtonStyled = styled(Button)`
  width: max-content;
`;

const CTAContainer = styled.div`
  display: flex;
  min-width: max-content;

  gap: var(--space-component-lg);
  justify-content: flex-end;

  container-type: inline-size;

  // On mobile, have buttons aligned on the same line
  @media (max-width: ${breakpoints.sm}) {
    a {
      width: 100%;
      justify-content: center;
      padding: 10px;
      div {
        display: none;
      }
    }
  }
`;

const CardContainer = styled.div<{
  maxAmountCTA: 2 | 1 | 0;
  addedPadding: number;
}>`
  display: flex;
  flex-direction: column;
  padding: var(--space-component-xxl);

  height: calc(100% - 200px);
  justify-content: space-between;

  container-type: inline-size;
  container-name: card-container;

  /*
    If any card has 2 CTAs, apply logic of breaking buttons into column
    based on the component query
  */
  ${({ maxAmountCTA, addedPadding }) =>
    maxAmountCTA === 2 &&
    `
  ${screen.sm} {
    @container (width < 320px) {

    /*
     Padding updated based on the amount of CTAs present on this card;
     No changes if 2 CTAs present
    */
    ${StyledStack} {
        ${
          addedPadding > 0 &&
          `
        padding-bottom:
          ${
            addedPadding === 2
              ? `calc(var(--space-component-xxl) + 7rem)`
              : addedPadding === 1 && `calc(var(--space-component-xxl) + 4rem)`
          };`
        }
    }
      ${CTAContainer} {
        flex-direction: column;
        a {
          width: 100%;
          max-width: 100%;
          justify-content: center;
          padding: 10px;
          div {
            display: none;
          }
        }
      }
     }
  `}
`;

const StyledBulletPoints = styled(BulletPoints)`
  padding-left: var(--space-component-sm);
  padding-top: var(--space-component-sm);
  color: var(--text-warm-base);
`;
