import { getImageProps } from 'next/image';

import { orderedBreakpointKeys, screen } from '@/components/common/breakpoints';

import type { BreakpointKeys } from '@/components/common/breakpoints';
import type { SanityImageAsset } from 'sanity-codegen';

export type ResponsiveImageSource = {
  image?: {
    asset: SanityImageAsset;
  };
  breakpoint: BreakpointKeys;
};

export type ResponsiveImage<T = unknown> = {
  images: (ResponsiveImageSource & T)[];
  alt: string;
};

interface ResponsiveImageProps<T = unknown> {
  responsiveImage: ResponsiveImage<T>;
  className?: string;
  style?: React.CSSProperties;
}

export const sortEntriesByBreakpoint = <T,>(
  entries: ({ breakpoint: BreakpointKeys } & T)[],
) => {
  return entries.sort(
    (a, b) =>
      orderedBreakpointKeys.indexOf(a.breakpoint) -
      orderedBreakpointKeys.indexOf(b.breakpoint),
  );
};

export const ResponsiveImage = ({
  responsiveImage,
  className,
  style,
}: ResponsiveImageProps) => {
  const { images, alt } = responsiveImage;

  if (!images?.some((item) => item.breakpoint === 'base')) {
    throw new Error('A Responsive Image must have one "base" breakpoint');
  }

  const sorted = sortEntriesByBreakpoint(images).reverse();

  const sources = sorted.map(({ image, breakpoint }) => {
    if (typeof image?.asset?.metadata === 'undefined') {
      throw new Error(
        'No asset data found. Maybe you forgot to dereference images in your query?',
      );
    }

    const { props } = getImageProps({
      alt: '',
      sizes: `100vw`,
      width: image?.asset?.metadata?.dimensions?.width,
      height: image?.asset?.metadata?.dimensions?.height,
      src: image?.asset.url,
    });

    return (
      <source
        key={props.key}
        media={screen[`${breakpoint}Query`]}
        srcSet={props.srcSet}
        width={props.width}
        height={props.height}
        sizes={props.sizes}
      />
    );
  });

  const { props: imgTag } = getImageProps({
    alt: '',
    width: sorted[0].image?.asset?.metadata?.dimensions?.width,
    height: sorted[0].image?.asset?.metadata?.dimensions?.height,
    src: sorted[0].image?.asset.url,
  });

  return (
    <picture>
      {sources}
      <img {...imgTag} alt={alt} className={className} style={style} />
    </picture>
  );
};
