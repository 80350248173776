import { useEffect, useState } from 'react';

import type {
  blockSpacingOptions,
  componentSpacingOptions,
} from '@/types/measures';

export const useSpacingValue = (
  token: componentSpacingOptions | blockSpacingOptions | 0,
) => {
  const [spacingValue, setSpacingValue] = useState(0);

  useEffect(() => {
    const getSpacingValue = (token) => {
      if (token === 0) return 0;
      const rootStyles = getComputedStyle(document.documentElement);
      const spacingREMValue = rootStyles.getPropertyValue(token);
      const spacingPixelValue = parseFloat(spacingREMValue) * 16;
      return spacingPixelValue;
    };

    setSpacingValue(getSpacingValue(token));
  }, [token]);

  return spacingValue;
};
