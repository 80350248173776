import { Loader } from '@/components/common/Loader';
import { FormError, HubspotForm } from '@/components/forms/HubspotForm';
import { useFormData } from '@/components/forms/useFormData';

export const AbFormContainer = ({
  hsId,
  breakpoints,
  endpoint,
  formType,
  ...props
}) => {
  const response = useFormData(hsId);
  const { data, error, isValidating } = response || {};

  if (data?.status === 'error' || error) return <FormError />;
  if (isValidating) return <Loader />;

  return (
    <HubspotForm
      formData={data}
      endpoint={endpoint}
      breakpoints={breakpoints}
      formType={formType}
      {...props}
    />
  );
};
