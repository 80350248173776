import styled from '@emotion/styled';

import { screen } from '@/components/common/breakpoints';
import { ColourfulArrows } from '@/components/common/Swiper/Custom/Navigation/ColourfulArrows';
import { DefaultArrows } from '@/components/common/Swiper/Custom/Navigation/DefaultArrows';
import {
  DetailedPagination,
  DetailedPaginationContainer,
  ProgressBar,
  ProgressBarInner,
} from '@/components/common/Swiper/Custom/Pagination/DetailedPagination';
import {
  MobileDetailedPagination,
  MobileDetailedPaginationContainer,
  MobileDetailedPaginationTab,
  MobileIndicatorContainer,
} from '@/components/common/Swiper/Custom/Pagination/MobileDetailedPagination';
import {
  PipContainer,
  PipPagination,
} from '@/components/common/Swiper/Custom/Pagination/PipPagination';
import {
  SimplePagination,
  SimplePaginationContainer,
} from '@/components/common/Swiper/Custom/Pagination/SimplePagination';

import type { SwiperProps } from '@/components/common/Swiper/Swiper';

export const renderPagination = (
  type: SwiperProps['pagination']['type'],
  slides,
  slidesPerViewValue,
  pagination,
  progressBarRef,
  mobileProgressBarRef,
) => {
  const totalPips = Math.ceil(slides.length / slidesPerViewValue); // e.g. 7 slides / 2 slides per view = 4 pips

  switch (type) {
    case 'detailed':
      return (
        <>
          <MobileDetailedPaginationContainer>
            <MobileIndicatorContainer>
              {slides.map((slide, index) => (
                <MobileDetailedPagination
                  key={slide.key}
                  index={index}
                  slidesPerView={slidesPerViewValue}
                  progressBarRef={mobileProgressBarRef}
                />
              ))}
            </MobileIndicatorContainer>
            <MobileDetailedPaginationTab components={pagination?.components} />
          </MobileDetailedPaginationContainer>

          <DetailedPaginationContainer
            className="custom-pagination"
            numberOfLogos={slides.length}
          >
            {slides.map((_, index) => (
              <DetailedPagination
                key={slides[index].key}
                index={index}
                slidesPerView={slidesPerViewValue}
                component={pagination?.components[index]}
                progressBarRef={progressBarRef}
              />
            ))}
          </DetailedPaginationContainer>
        </>
      );
    case 'simple':
      return (
        <SimplePaginationContainer>
          {slides.map((slide, index) => (
            <SimplePagination
              key={slide.key}
              index={index}
              slidesPerView={slidesPerViewValue}
              progressBarRef={mobileProgressBarRef}
            />
          ))}
        </SimplePaginationContainer>
      );
    case 'progressbar':
      return (
        <StyledProgressBar className="styled-progress-bar" isActive={true}>
          <ProgressBarInner className="progress-bar-inner" />
        </StyledProgressBar>
      );
    default:
    case 'pip':
      return (
        <PipContainer className="custom-pagination">
          {Array.from({ length: totalPips }).map((_, index) => (
            <PipPagination
              key={index}
              slidesPerView={slidesPerViewValue}
              pipIndex={index}
            />
          ))}
        </PipContainer>
      );
  }
};

// Styled for SliderCarousel
const StyledProgressBar = styled(ProgressBar)`
  position: relative;
  width: 112px;
  ${screen.sm} {
    width: 144px;
  }
  justify-content: center;
  margin-top: var(--space-component-xl);

  ${ProgressBarInner} {
    width: 32px;
  }
`;

export const renderNavigation = (type, prevButtonRef, nextButtonRef) => {
  switch (type) {
    case 'colourful':
      return (
        <ColourfulArrows
          prevButtonRef={prevButtonRef}
          nextButtonRef={nextButtonRef}
        />
      );
    case 'default':
      return (
        <DefaultArrows
          prevButtonRef={prevButtonRef}
          nextButtonRef={nextButtonRef}
        />
      );
  }
};

export const getClosestBreakpoint = (
  screenSize: number,
  config: Record<string, number>,
): number => {
  const breakpointKeys = Object.keys(config)
    .map((key) => parseInt(key, 10))
    .sort((a, b) => a - b);

  const closestBreakpoint =
    breakpointKeys.find((breakpoint) => breakpoint >= screenSize) || 'max';

  const closestBreakpointLookup =
    closestBreakpoint === 'max' ? 'max' : `${closestBreakpoint}px`;

  return config[closestBreakpointLookup];
};
