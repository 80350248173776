import styled from '@emotion/styled';

import { Swiper } from '@/components/common/Swiper';
import { Section } from '@/components/layout/Section';

import 'swiper/css/effect-fade';

import { SliderCarouselCard } from '@/components/blocks/SliderCarousel/Card/SliderCarouselCard';
import { breakpoints, screen } from '@/components/common/breakpoints';
import { CourseCard } from '@/components/page/courses/CourseCard';
import { useMediaQuery } from '@/lib/useMediaQuery';
import { blockUrlWithAudience } from '@/lib/utils';

import type { SliderCarouselCardProps } from '@/components/blocks/SliderCarousel/Card/SliderCarouselCard';
import type { CourseCardProps } from '@/components/page/courses/CourseCard';
import type {
  SanityAudienceType,
  SanitySliderCarouselType,
} from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';
import type { ReactNode } from 'react';

interface SlideType {
  slides?:
    | Array<
        Omit<SliderCarouselCardProps, 'audienceRef'> & {
          audienceRef?: SanityAudienceType;
        }
      >
    | Array<CourseCardProps>;
}

export interface SliderCarouselProps
  extends Omit<SanitySliderCarouselType, 'slides' | '_type'>,
    BlockWithAudience,
    SlideType {
  slidesPerView?: Record<string, number>;
  slidesPerGroup?: Record<string, number>;
  addSection?: boolean;
}

export const SliderCarousel = ({
  slides,
  pageAudience,
  isAudienceSwitcherEnabled,
  slidesPerView = {
    ['640px']: 1.01,
    ['960px']: 2.01,
    max: 3.01,
  },
  slidesPerGroup = {
    [breakpoints['sm']]: 1,
    [breakpoints['md']]: 2,
    [breakpoints['lg']]: 3,
    [breakpoints['xl']]: 3,
    max: 3,
  },
  blockSpacing,
  paddingAdjustment,
  addSection = true,
}: SliderCarouselProps) => {
  const maxAmountCTA = () => {
    if (slides.some((slide) => slide.secondaryCTA?.text)) {
      return 2;
    } else if (slides.some((slide) => slide.primaryCTA?.text)) {
      return 1;
    }
    return 0;
  };

  const slideComponents = slides.map((slide) => {
    if (slide._type === 'sliderCarouselCard') {
      return (
        <SliderCarouselCard
          key={slide._key}
          maxAmountCTA={maxAmountCTA()}
          pageAudience={pageAudience}
          isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
          {...slide}
        />
      );
    }
    if (slide._type === 'programmeCard') {
      const href = blockUrlWithAudience(
        `/courses/${slide?.href}${slide?.b2cAudience ? `?b2cAudience=${slide?.b2cAudience}` : ''}`,
        slide?.audienceRef,
        pageAudience,
        slide?.noAudienceToggle,
        isAudienceSwitcherEnabled,
      );
      return <CourseCard key={slide._key} href={href} {...slide} />;
    }
  });

  const isDesktop = useMediaQuery(screen.mdQuery);

  const Slider = (
    <Swiper
      slides={slideComponents}
      pagination={{
        enabled: true,
        type: isDesktop ? 'pip' : 'progressbar',
      }}
      navigation={{
        enabled: isDesktop,
      }}
      effect="slide"
      autoplay={{ enabled: false }}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      breakpoints={{
        base: {
          spaceBetween: 20,
          freeMode: {
            enabled: true,
          },
        },
        md: {
          spaceBetween: 20,
          freeMode: {
            enabled: false,
          },
        },
        lg: {
          spaceBetween: 24,
        },
      }}
    />
  );

  const ConditionalSectionWrapper = (children: ReactNode) => {
    if (addSection) {
      return (
        <Section
          className="slider-carousel-container"
          verticalPadding={paddingAdjustment ?? true}
          spacing={blockSpacing}
        >
          {children}
        </Section>
      );
    } else return children;
  };

  return ConditionalSectionWrapper(
    <SliderCarouselContainer slidesPerView={slidesPerView}>
      {Slider}
    </SliderCarouselContainer>,
  );
};

/*
  Due to some flickering issues on specific width of the screen,
  this rule checks if there're {slidesPerView.max} fully-visible elements or
  {slidesPerView.max - 1} fully-visible + active,
  if there're not, add an opacity:1 to the last element
 */
const buildFlickeringFixStyles = (
  slidesPerView: SliderCarouselProps['slidesPerView'],
) => {
  const styles = Object.entries(slidesPerView).map(
    ([breakpoint, slidesAmount]) => {
      // As slides per view changes, rules should reflect it based on the breakpoints and amount of slides.
      // For the "max" value, we figure the largest breakpoint and make it min-width
      const mediaQuery = () => {
        if (breakpoint !== 'max') {
          return `@media(min-width: 960px) and (max-width: ${breakpoint})`;
        } else {
          const breakpointNumberValues = Object.keys(slidesPerView)
            .filter((key) => key !== 'max')
            .map((key) => parseInt(key.replace('px', '')));
          const sortedValues = breakpointNumberValues.sort((a, b) => b - a);
          const biggestBreakpoint = sortedValues[0];

          return `@media(min-width: ${biggestBreakpoint}px)`;
        }
      };

      return `
        ${mediaQuery()} {
          .swiper-wrapper:not(
              :has(
                  .swiper-slide-fully-visible:nth-last-of-type(
                      ${Math.floor(slidesAmount) + 1}
                    )
                )
            ):not(
              :has(
                  .swiper-slide-active:nth-last-of-type(
                      ${Math.floor(slidesAmount) + 1}
                    )
                )
            ) {
            .swiper-slide:nth-last-of-type(1) {
              opacity: 1;
            }
          }
        }
      `;
    },
  );

  return styles.join(' ');
};

const SliderCarouselContainer = styled.div<{
  slidesPerView: SliderCarouselProps['slidesPerView'];
}>`
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
  }

  // On desktop adding opacity animation on previous and next slides
  ${screen.md} {
    .swiper-slide {
      opacity: 0.24;
      transition-property: opacity;
      transition-duration: 0.25s;
      transition-timing-function: ease-in;
    }

    .swiper-slide-fully-visible {
      opacity: 1;
    }

    .swiper-slide-active {
      opacity: 1;
    }

    ${({ slidesPerView }) => buildFlickeringFixStyles(slidesPerView)}
  }
`;
