import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSwiper } from 'swiper/react';

export const PipPagination = ({ pipIndex, slidesPerView }) => {
  const swiper = useSwiper();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleSlideChange = () => {
      setActiveIndex(swiper.activeIndex);
    };

    swiper.on('slideChange', handleSlideChange);
    return () => {
      swiper.off('slideChange', handleSlideChange);
    };
  }, [swiper]);

  return (
    <Pip
      className={`custom-bullet ${Math.ceil(activeIndex / slidesPerView) === pipIndex ? 'active' : ''}`}
      onClick={() => swiper.slideTo(pipIndex * slidesPerView)}
    />
  );
};

const Pip = styled.div`
  height: 2px;
  width: 14px;
  border-radius: var(--radius-full);
  background: var(--border-warm-base);
  cursor: pointer;

  &.active {
    height: 3px;
    background: var(--border-action);
  }
`;

export const PipContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
